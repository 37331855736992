import { CouponType, DocumentType } from '@innedit/innedit-type';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '../../../containers/Espace/props';
import ListItem from './index';

const ListItemCoupon: FC<ListItemProps<CouponType>> = ({
  docId,
  index,
  model,
  onClick,
}) => {
  const [doc, setDoc] = useState<DocumentType<CouponType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  return (
    <ListItem
      contentClassName="flex flex-col"
      displayActionId
      doc={doc}
      index={index}
      onClick={onClick}
    >
      <strong>{doc.name}</strong>
      <span>{doc.code}</span>
    </ListItem>
  );
};

export default ListItemCoupon;
